import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to prepare for a high-demand hardware launch",
  "date": "2020-11-09",
  "slug": "blog/how-to-prepare-for-a-high-demand-hardware-launch",
  "featuredImage": "./images/prepare-for-launch.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Tip 1:`}</h2>
    <p>{`Try to sign up for alerts for the products you want from as many retailers as possible. Keep your eye out for product pages listed before launch and look for “Notify me” or “Email me” options for alerts. We’ll keep helping you destress around launch time if you just follow our blog!`}</p>
    <h2>{`Tip 2:`}</h2>
    <p>{`Check to make sure you have an account created at all retailers and you're signed in on each retailer. Check to make sure you're signed in once every few days as sign ins expire.`}</p>
    <h2>{`Tip 3:`}</h2>
    <p>{`Make sure your check out information is saved and up to date at the retailers (Address, credit card, etc) so you can complete checkout with fresh information.`}</p>
    <h2>{`Tip 4:`}</h2>
    <p>{`Successful purchasers have reported that using the Newegg and Best Buy mobile apps with Apple Pay has expedited their checkout process significantly.`}</p>
    <h2>{`Tip 5:`}</h2>
    <p>{`Chrome mobile/tablet has a new Lite mode which helps load pages faster. Purchasers have reported success using this with Best Buy via mobile browser. More info on Lite mode: `}<a parentName="p" {...{
        "href": "https://support.google.com/chrome/answer/2392284?co=GENIE.Platform%3DAndroid&hl=en"
      }}>{`https://support.google.com/chrome/answer/2392284?co=GENIE.Platform%3DAndroid&hl=en`}</a></p>
    <p>{`If you have more tips to add, please also join the community on Discord and let us know! New tips will be posted here - so bookmark this page!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      